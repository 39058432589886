:root {
	--green: #009a2e;
	--orange: #ff8a00;
	--grey: #f6f6f6;
	--greyDark: #e5e5e5;
}
html,body,#root,.App {
	height: 100%;
	display: flex;
	flex-direction: column;
}
body {
	font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 14px;
}
// BUTTONS
.btn {
	min-height: 48px;
	border-radius: 0;
}
.btn-primary,.btn-primary.disabled,.btn-primary:disabled,.btn-primary:hover,.btn-primary:focus,.btn-primary:not(:disabled):not(.disabled).active,.btn-primary:not(:disabled):not(.disabled):active,.show>.btn-primary.dropdown-toggle {
	background-color: var(--green);
	border-color: var(--green);
}
.btn-primary:not(:disabled):not(.disabled).active:focus,.btn-primary:not(:disabled):not(.disabled):active:focus,.show>.btn-primary.dropdown-toggle:focus,.btn-primary.focus,.btn-primary:focus {
	box-shadow: rgba(var(--green), 0.2)
}
.btn-outline-primary,.btn-outline-primary.disabled,.btn-outline-primary:disabled,.btn-outline-primary:hover,.btn-outline-primary:focus,.btn-outline-primary:not(:disabled):not(.disabled).active,.btn-outline-primary:not(:disabled):not(.disabled):active,.show>.btn-outline-primary.dropdown-toggle {
	border-color: var(--green);
	color: var(--green)
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,.btn-outline-primary:not(:disabled):not(.disabled):active:focus,.show>.btn-outline-primary.dropdown-toggle:focus,.btn-outline-primary.focus,.btn-outline-primary:focus {
	box-shadow: rgba(var(--green), 0.2)
}
.btn-secondary,.btn-secondary.disabled,.btn-secondary:disabled,.btn-secondary:hover,.btn-secondary:focus,.btn-secondary:not(:disabled):not(.disabled).active,.btn-secondary:not(:disabled):not(.disabled):active,.show>.btn-secondary.dropdown-toggle {
	background-color: var(--orange);
	border-color: var(--orange);
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,.btn-secondary:not(:disabled):not(.disabled):active:focus,.show>.btn-secondary.dropdown-toggle:focus,.btn-secondary.focus,.btn-secondary:focus {
	box-shadow: rgba(var(--orange), 0.2)
}
.btn-outline-secondary,.btn-outline-secondary.disabled,.btn-outline-secondary:disabled,.btn-outline-secondary:hover,.btn-outline-secondary:focus,.btn-outline-secondary:not(:disabled):not(.disabled).active,.btn-outline-secondary:not(:disabled):not(.disabled):active,.show>.btn-outline-secondary.dropdown-toggle.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle,.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus {
	border-color: var(--orange);
	color: var(--orange);
	background-color: #fff;
	box-shadow:unset;
}
.btn-outline-secondary:hover.btn-outline-secondary:not(:disabled):not(.disabled).active,.btn-outline-secondary:not(:disabled):not(.disabled):active,.show>.btn-outline-secondary.dropdown-toggle {
	border-color: var(--orange);
	color: var(--orange)
}
.btn-link,.btn-link.disabled,.btn-link:disabled,.btn-link:hover,.btn-link:focus,.btn-link:not(:disabled):not(.disabled).active,.btn-link:not(:disabled):not(.disabled):active,.show>.btn-link.dropdown-toggle {
	background-color: var(--grey);
	color: var(--orange);
	text-decoration: none;
}
.btn-linkactions,.btn-linkactions.disabled,.btn-linkactions:disabled,.btn-linkactions:hover,.btn-linkactions:focus,.btn-linkactions:not(:disabled):not(.disabled).active,.btn-linkactions:not(:disabled):not(.disabled):active,.show>.btn-linkactions.dropdown-toggle {
	border-color: var(--orange);
	color: var(--orange);
	text-decoration: none;
	background-color: #fff;
}

.btn-linkactions.finished {
	color: var(--green)
}

.btn.btn-full {
	@media screen and (min-width: 768px) {
		width: 50%;
		margin: 0 auto 20px;
	}
}
// NAV
.navbar-light {
	background-color: var(--green);
	color: #fff;
	height: 70px;
	min-height: 70px;
}
// PADDING & MARGIN
.pb-6 {
	padding-bottom: 4.5rem;
}
// BADGE
.badge.badge-primary {
	background-color: var(--orange);
}
// CALENDAR
.react-calendar__tile--now {
	background-color: var(--green) !important;
	color: #fff;
	//border: 1px solid #fff !important;
}
.react-calendar__tile--active,.react-calendar__tile--active:enabled:hover,.react-calendar__tile--active:enabled:focus {
	background-color: var(--orange) !important;

}
// OTHER
.bg-grey {
	background-color: var(--greyDark);
	width: 100%;
	height: 40px;
	line-height: 40px;
	padding-left: 15px;
	font-size: 14px;
}
